.user-row {
  // cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 20px 25px;
  border: 1px solid #e4e4e4;
  margin-bottom: 15px;
  border-radius: 5px;
  // margin-top: 1rem;
  transition: all 0.2s;
  &:hover {
    // transform: scale(1.01);
    border: 1px solid gray;
  }
}
.user-row-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  p {
    margin: 0;
    direction: ltr;
  }
}
.edit-user-pen {
  height: 100%;
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}
