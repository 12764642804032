.site--modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba($color: #000000, $alpha: 0.5);
	z-index: 5000;
	display: flex;
	align-items: center;
	justify-content: center;

	.site--modal--body {
		background-color: #fff;
		border-radius: 5px;
		padding: 30px;
		max-width: 450px;
		width: 100%;

		.site--modal--header {
			margin-bottom: 25px;
			position: relative;

			.site--modal--close {
				width: 20px;
				height: 20px;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				cursor: pointer;

				img {
					width: 100%;
					height: 100%;
				}
			}

			h3 {
				margin: 0;
			}
		}
	}
}
