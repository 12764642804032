.report-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 5vh;
  border-bottom: 1px solid gray;
  padding: 0 10px 0 10px;
}
.report-item-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 70%;
  input {
    margin-left: 5px;
  }
}
.item-title {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}
