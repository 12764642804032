.tree-actions {
  margin-bottom: 20px;

  button {
    margin-left: 15px;
    border: none;
    background-color: #38a6d6;
    color: #fff;
    cursor: pointer;
    padding: 8px 15px;

    &:nth-child(3) {
      background-color: #fff;
      border: 1px solid #38a6d6;
      color: #38a6d6;
    }
  }
}

.add-tree-form {
  grid-template-columns: 47% 47%;
  grid-gap: 6%;
  flex-direction: column;
  position: relative;

  .tree-icon {
    max-width: 150px;
    display: block;
    margin-bottom: 10px;
  }

  .tree-report {
    position: relative !important;
    top: initial !important;
    left: initial !important;
    right: initial !important;
    bottom: initial !important;
    width: 100% !important;
    white-space: wrap !important;
    // text-overflow: ellipsis;
    overflow: hidden;
    height: max-content;
    word-wrap: break-word;
    word-break: break-all;
    display: flex;
    justify-content: right;
    align-items: flex-start;

    padding: 5px 25px;

    input[type='checkbox'] {
      margin-left: 15px;
    }

    span {
      display: inline-block;
    }
  }

  .submit-container {
    position: absolute;
    left: 5px;
    bottom: 5px;
  }
}

.tree-wrapper {
  height: 100%;
  position: relative;
  padding-right: 20%;

  .saving-tree {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
    z-index: 500;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba($color: #000000, $alpha: 0.5);
  }

  .tree-edit {
    border: none;
    background-color: #38a6d6;
    color: #fff;
    cursor: pointer;
    padding: 5px;
    margin-right: 8px;

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .rst__rowContents {
    min-width: 280px;
  }

  .rst__moveHandle {
    background: #38a6d6
      url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+)
      no-repeat center;
  }
}

.report-breadcrumbs {
  display: flex;
  gap: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;

  .report-breadcrumbs-item {
    color: black;
    height: 30px;
    display: flex;
    gap: 5px;
    flex-direction: row-reverse;
  }

  .report-breadcrumbs-seperator {
    font-weight: 700;
  }
}
