.permission-tree-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.permission-tree-user {
    height: 10%;
}
