.users-scroll {
  width: 100%;
  height: 100%;
}
.user-scroll-top {
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
}
.user-scroll-top-tabs {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
