.users-page {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 30px;
  overflow: visible scroll;
}

.users-page-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  padding: 0 20%;

  @media only screen and (max-width: 1250px) {
    padding: 0 12%;
  }
}

.users-edit-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-right: 25px;
  // align-items: flex-end;

  min-width: 40%;

  .user-edit-actions {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .actions-btns {
      display: flex;
    }

    .site-btn {
      margin-left: 0;
      margin-right: 15px;
    }
  }

  .user-edit-password,
  .user-edit-role {
    margin-bottom: 10px;

    input {
      width: 100%;
    }
  }

  > ul {
    padding: 0;

    li {
      margin-bottom: 15px;

      > ul {
        margin-top: 15px;
      }

      > label {
        font-size: 22px;
        display: flex;
        align-items: center;

        span {
          margin-right: 10px;
        }
      }
    }
  }
}

.users-list {
  // width: 65%;
  width: 100%;
}

.row-part {
  width: 25%;
  // text-align: center;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.id {
    width: 15%;
  }

  &.username {
    width: 35%;
  }
}
.users-wrapper {
  width: 100%;
  // height: 90%;
  // max-height: 90%;
  // overflow: auto;
}

.users-header {
  display: flex;
  position: relative;
  left: -35px;

  p:first-child {
    flex: 20%;
  }

  p:nth-child(2) {
    flex: 20%;
  }

  p:nth-child(3) {
    flex: 20%;
  }

  p:nth-child(4) {
    flex: 29%;
  }

  p:nth-child(5) {
    flex: 11%;
  }
}

.user-tree {
  width: 45%;
  height: 100%;
}
