@import 'normalize';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

@import 'variables';
@import 'fonts';

@import 'components/node-renderer.scss';

@import 'pages/login';
@import 'pages/loading';

@import './components/header';
@import './components/reportsScroll';
@import './components/reportItem';
@import './components/siteTree';
@import './pages/siteReports';
@import './components/editReportsScroll';
@import './components/editReportRow';
@import './pages/userPage';
@import './components/usersScroll';
@import './components/userRow';
@import './components/permissionTree';
@import './components/siteModal';
@import './components/loader';

* {
  direction: rtl;
  text-align: right;
}

body,
html,
input,
select,
span,
strong,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Almoni Neue DL 4.0 AAA';
}

#root {
  height: 100%;
}

.site-btn {
  margin-left: 15px;
  border: none;
  background-color: #38a6d6;
  color: #fff;
  cursor: pointer;
  padding: 8px 15px;
}

input[type='text'] {
  border: 1px solid #38a6d6;
  padding: 10px;
  border-radius: 0;
  outline: none;
}

.form-error {
  color: red;
  margin: 10px 0;
  font-size: 14px;
  font-weight: 500;
}

.group-container {
  display: flex;
}

.form-group {
  margin-bottom: 15px;
  width: 100%;
  position: relative;

  > label {
    margin-bottom: 5px;
    font-weight: 500;
    display: block;
    font-weight: bold;
  }

  .x-button {
    outline: none;
    border: none;
    border-radius: 50%;
    color: white;
    background: transparent;
    font-size: 15px;
    padding: 4px 7px 1.5px 8px;
    text-align: center;
  }

  input[type='text'] {
    width: 100%;
  }

  .date-formatted {
    position: absolute;
    right: 27px;
    top: 26px;
    cursor: default;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }

  input[type='date']::-webkit-datetime-edit-text {
    -webkit-appearance: none;
    display: none;
  }

  input[type='date']::-webkit-datetime-edit-month-field {
    -webkit-appearance: none;
    display: none;
  }

  input[type='date']::-webkit-datetime-edit-day-field {
    -webkit-appearance: none;
    display: none;
  }

  input[type='date']::-webkit-datetime-edit-year-field {
    -webkit-appearance: none;
    display: none;
  }
}

main.main {
  height: calc(100% - 120px);
  overflow: visible;

  > .container {
    height: 100%;
  }
}

.site-btn {
  margin-left: 15px;
  border: none;
  background-color: #38a6d6;
  color: #fff;
  cursor: pointer;
  padding: 11px 15px;
}

.site-title {
  font-weight: bold;
  text-decoration: underline;
  font-size: 38px;
  margin: 0;
  padding: 20px 0;
}

.site-title-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20%;

  @media only screen and (max-width: 1250px) {
    padding: 0 12%;
  }

  input[type='search'],
  select {
    outline: none;
    border: 1px solid #38a6d6;
    padding: 10px;
    min-width: 100px;
    margin: 0 15px;
  }
}

.ReactVirtualized__Grid {
  width: 100% !important;
}
