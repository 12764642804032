.reports-page {
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reports-page-content {
  width: 70%;
  height: 100%;
  // border: 1px solid green;
  padding: 1rem;
}
.report-page-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  input {
    height: 1.5vw;
  }
}
.reports-header {
  font-size: 2vw;
  text-decoration: underline;
}

.site-title-actions-inner {
}

.checkbox__container {
  margin: 10px 0 0;
  display: flex;
  align-items: center;
  label {
    margin: 0 5px 0 10px;
  }
}

.reports-table__header {
  display: flex;
  // grid-gap: 1%;
  padding: 10px;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
  position: sticky;
  top: 0;
  background-color: #fff;

  & > div {
    &:nth-child(1) {
      flex: 10%;
    }

    &:nth-child(2) {
      flex: 15%;
    }

    &:nth-child(3) {
      flex: 20%;
    }

    &:nth-child(4) {
      flex: 10%;
    }

    &:nth-child(5) {
      flex: 10%;
    }

    &:nth-child(6) {
      flex: 7%;
    }

    &:nth-child(7) {
      flex: 13%;
    }
  }
}

.select {
  width: 200px;
}
