.edit-report-row {
  width: 100%;
  height: auto;
  padding: 20px 25px;
  border: 1px solid #e4e4e4;
  margin-bottom: 15px;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 10% 15% 25% 10% 10% 10% 10% auto;
  grid-gap: 1%;
  align-items: center;
  transition: all 0.2s;
  &:hover {
    // transform: scale(1.01);
    border: 1px solid gray;
  }
}

.row-edit-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}

.iframe-url {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  direction: ltr;
}

.createdBy {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}

.modifiedBy {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}

.related {
  width: 30%;
}

.middle-row {
  height: 80%;
  width: 1px;
  background-color: rgba(128, 128, 128, 0.527);
}
.row-id-wrapper {
  width: auto;
  padding-left: 50px;
  font-size: 18px;
  font-weight: 500;
  min-width: 100px;
}
.trash-can {
  color: #b61512;
  font-size: 14px;
  //width: 20%;
  cursor: pointer;
  &:hover {
    text-shadow: 2px 2px 13px darken($color: #b61512, $amount: 7%);
    //color: rgb(219, 47, 47);
    transform: scale(1.1);
  }
}
.edit-report-row-left {
  // width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.row-edit-text {
  font-size: 16px;

  @media only screen and (max-width: 1400px) {
    font-size: 14px;
  }

  &.date {
    min-width: 90px;
    display: flex;
    align-items: center;
  }
}
.edit-pen {
  font-size: 14px;
  cursor: pointer;
  padding: 0 10px;
  &:hover {
    color: #2bb2d3;
    transform: scale(1.1);
  }
}

.draggable-report {
  padding: 5px;
  background: white;
  width: 80%;
}
