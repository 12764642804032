.header {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  height: 120px;
  box-shadow: -10px -20px 30px rgba(0, 0, 0, 0.5);
}

.header-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.header-img-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 190px;

  img {
    width: 100%;
    height: 100%;
  }
}

.header-left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  a {
    margin-right: 15px;
  }
}

.header-link {
	font-size: 18px;

  text-decoration: none;
}

.lactive-class {
  color: #3f4140;
  text-decoration: underline;
  font-weight: bold;
}

.not-active-class {
  color: #3f4140;
}
