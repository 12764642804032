@font-face {
  font-family: 'Almoni Neue DL 4.0 AAA';
  src: url('../fonts/almoni-neue-light-aaa.eot');
  src: url('../fonts/almoni-neue-light-aaa.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/almoni-neue-light-aaa.woff2') format('woff2'),
    url('../fonts/almoni-neue-light-aaa.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Almoni Neue DL 4.0 AAA';
  src: url('../fonts/almoni-neue-regular-aaa.eot');
  src: url('../fonts/almoni-neue-regular-aaa.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/almoni-neue-regular-aaa.woff2') format('woff2'),
    url('../fonts/almoni-neue-regular-aaa.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Almoni Neue DL 4.0 AAA';
  src: url('../fonts/almoni-neue-medium-aaa.eot');
  src: url('../fonts/almoni-neue-medium-aaa.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/almoni-neue-medium-aaa.woff2') format('woff2'),
    url('../fonts/almoni-neue-medium-aaa.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Almoni Neue DL 4.0 AAA';
  src: url('../fonts/almoni-neue-bold-aaa.eot');
  src: url('../fonts/almoni-neue-bold-aaa.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/almoni-neue-bold-aaa.woff2') format('woff2'),
    url('../fonts/almoni-neue-bold-aaa.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Almoni Neue DL 4.0 AAA';
  src: url('../fonts/almoni-neue-black-aaa.eot');
  src: url('../fonts/almoni-neue-black-aaa.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/almoni-neue-black-aaa.woff2') format('woff2'),
    url('../fonts/almoni-neue-black-aaa.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
