.editReportsScroll {
  height: calc(100vh - 270px) !important;
  width: 100%;
  padding: 0 20%;

  @media only screen and (max-width: 1250px) {
    padding: 0 12%;
  }
}

.ReactVirtualized__Grid__innerScrollContainer.custom {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr !important;
  max-width: 150% !important;
  overflow: hidden !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
